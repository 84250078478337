<template>
  <card-container backButton closeButton>
    <div class="p-8 mt-16">
      <div class="text-3xl text-center">Spørsmål og svar</div>
      <div class="mt-6 space-y-4 text-sm">
        <div>
          <div class="font-bold">Hva får man ekstra hvis man betaler for Telt?</div>
          <div>
            Da får du mulighet til å lagre favoritter, sjekke inn på stedene du har besøkt og
            filtrere søk.
          </div>
        </div>
        <div>
          <div class="font-bold">Hvorfor koster det penger?</div>
          <div>
            Telt er i utgangspunktet et hobbyprosjekt men det koster litt å drifte appen. Hvis du
            ikke vil betale kan du lagre bokmerker i nettleseren din for favoritter og steder du har
            besøkt.
          </div>
        </div>
        <div>
          <div class="font-bold">
            Jeg synes at x kroner i året er alt for billig, kan jeg betale mer?
          </div>
          <div>
            Oj... Ja, det kan du faktisk. Jeg har en eget Patreon-side hvor du kan bidra med
            valgfritt beløp. Tusen takk!
          </div>
        </div>
      </div>
    </div>
  </card-container>
</template>
<script>
import CardContainer from '../components/CardContainer.vue'
export default {
  components: { CardContainer },
}
</script>
